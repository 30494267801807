<script lang="ts" setup>
const cookieConsent = ref<string>();

const getConsent = () => {
  cookieConsent.value = localStorage.getItem("cookieConsent") || undefined;
};

const consent = () => {
  localStorage.setItem("cookieConsent", "yes");
  getConsent();
};

const style = {
  backgroundColor: "black",
  color: "white",
};

getConsent();
</script>

<template>
  <a-drawer
    placement="bottom"
    height="auto"
    :visible="!cookieConsent"
    :closable="false"
    :drawer-style="style"
  >
    <div class="flex items-center gap-2">
      <div class="flex-grow">
        {{ $t("cookie.intro") }}
      </div>
      <a-button type="primary" @click="consent">
        {{ $t("cookie.accept") }}
      </a-button>
    </div>
  </a-drawer>
</template>
