<script lang="ts" setup>
const { $TolgeeProvider } = useNuxtApp();
</script>

<template>
  <component :is="$TolgeeProvider">
    <main
      class="flex flex-col absolute inset-0 bg-white p-4 max-w-7xl lg:mx-auto"
    >
      <slot />
    </main>
    <CommonCookieConsent />
  </component>
</template>
